<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="5">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      title="Chương trình đào tạo/Bồi dưỡng"
                      label-for="trainingSystemSelect"
                    >
                      <v-select
                        v-model="trainingSystemSelect"
                        label="name"
                        :options="allTrainingSystems"
                        :reduce="(option) => option.id"
                        placeholder="Chương trình đào tạo/Bồi dưỡng"
                        @input="onChangeTrainingSystem"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      title="Khóa - Học kỳ"
                      label-for="coursesSemestersSelected"
                    >
                      <v-select
                        v-model="coursesSemestersSelected"
                        label="name"
                        :options="coursesSemesters"
                        :reduce="(option) => option.id"
                        placeholder="Khóa - Học kỳ"
                        @input="onChangeCoursesSemester"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      title="Học phần"
                      label-for="subjectSelect"
                    >
                      <v-select
                        v-model="courseSemesterSubjectSelect"
                        label="name"
                        :options="courseSemesterSubject"
                        :reduce="(option) => option.id"
                        placeholder="Học phần"
                        @input="onChangeCourseSemesterSubject"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <div class="text-left">
                      <b-button
                        v-if="showButton"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="onSelectExamsSelected"
                      >
                        <span class="text-nowrap text-right">Danh sách đề thi trong học kỳ</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= dataTotalRows)? dataTotalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ dataTotalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSource"
                  :pagination-options="paginationOptions"
                  :total-rows="dataTotalRows"
                  line-numbers
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'action'">
                      <b-button-group>
                        <b-button
                          v-show="exportPdfExam"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mr-1"
                          variant="success"
                          @click="exportPdfStudent(props.row)"
                        >
                          <span class="text-nowrap text-right">
                            <feather-icon icon="CheckIcon" /> Xuất đề cho học viên
                          </span>
                        </b-button>
                      </b-button-group>
                    </span>
                    <span v-else-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= dataTotalRows)? dataTotalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ dataTotalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="dataTotalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup,
  BCard,
  BCol,
  BContainer, BDropdown,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import { QB_EXAM_STATUSES } from '@/const/status'

export default {
  name: 'ExaminationRoom',
  directives: {
    Ripple,
  },
  components: {
    BButtonGroup,
    BDropdown,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      trainingSystemSelect: undefined,
      coursesSemestersSelected: undefined,
      courseSemesterSubjectSelect: undefined,
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        subjectId: null,
        courseSemesterId: null,
        code: null,
        name: null,
        status: 2,
      },
      paginationOptions: {
        enabled: true,
      },
      showButton: false,
      selectedMainData: [],
      dataSource: [],
      dataTotalRows: 0,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      dataSourcesProclaimed: 'qbExams/dataSourcesProclaimed',
      totalRowsProclaimed: 'qbExams/totalRowsProclaimed',
      allTrainingSystems: 'qbExams/allTrainingSystems',
      coursesSemesters: 'qbExams/coursesSemesters',
      courseSemesterSubject: 'qbExams/listCourseSemesterSubject',
    }),
    columns() {
      const baseColumns = [
        {
          label: 'Tên đề thi',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học phần',
          field: 'subjectName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
      return baseColumns
    },
    exportPdfExam() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.FETCH, ResourceCode.EXPORT_QB_EXAM_COURSE_SEMESTER)
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'qbExams/getTrainingSystemsByOrganizationId',
      getCourseSemesters: 'qbExams/getCourseSemesters',
      getCourseSemesterSubjectSelect: 'qbExams/getCourseSemesterSubject',
      getQbExamProclaimed: 'qbExams/getQbExamProclaimed',
      exportExam: 'qbExams/exportPdf',
      exportExamTeacher: 'qbExams/exportExamTeacher',
    }),
    async onChangeTrainingSystem() {
      if (this.trainingSystemSelect == null) {
        this.reset()
        this.$store.commit('qbExams/SET_COURSES_SEMESTERS', {
          data: [],
        })
        this.$store.commit('qbExams/SET_COURSE_SEMESTER_SUBJECT', {
          data: [],
        })
        this.coursesSemestersSelected = null
        this.courseSemesterSubjectSelect = null
      } else {
        await this.getCourseSemesters({
          organizationId: getUser().orgId,
          training_system_id: this.trainingSystemSelect,
        })
      }
    },
    async onChangeCoursesSemester() {
      if (this.coursesSemestersSelected == null) {
        this.reset()
        this.$store.commit('qbExams/SET_COURSE_SEMESTER_SUBJECT', {
          data: [],
        })
        this.courseSemesterSubjectSelect = null
      } else {
        await this.getCourseSemesterSubjectSelect({ organizationId: getUser().orgId, courses_semester_id: this.coursesSemestersSelected, hasExam: 1 })
      }
    },
    async onChangeCourseSemesterSubject() {
      if (this.courseSemesterSubjectSelect == null) {
        this.reset()
      } else {
        this.showButton = true
      }
    },
    reset() {
      this.showButton = false
      this.$store.commit('qbExams/SET_DATA_SOURCES_PROCLAIMED', {
        total: 0,
        records: [],
      })
    },
    async onSelectExamsSelected() {
      await this.getExamsFromStore()
    },
    async exportPdfStudent(dataExam) {
      this.isLoading = true
      try {
        const data = await this.exportExam({
          qbExamId: dataExam.id,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfTeacher(dataExam) {
      this.isLoading = true
      try {
        const data = await this.exportExamTeacher({
          qbExamId: dataExam.id,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async getExamsFromStore() {
      this.isLoading = true
      try {
        this.filter.subjectId = this.courseSemesterSubjectSelect
        this.filter.courseSemesterId = this.coursesSemestersSelected
        await this.getQbExamProclaimed(this.filter)
        this.dataSource = this.dataSourcesProclaimed
        this.dataTotalRows = this.totalRowsProclaimed
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getExamsFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getExamsFromStore()
    },
    async onSortChange() {
      await this.getExamsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'subject')) this.updateParams({ subject: columnFilters.subject })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getExamsFromStore()
    },
    getStatusName(id) {
      return QB_EXAM_STATUSES.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getExamsFromStore()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
